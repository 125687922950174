import queryString from 'querystring';
import React, {  useEffect } from "react";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Helmet } from 'react-helmet-async';
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Card, CardBody, } from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import AdsTable from './components/AdsTable';

export default function Ads() {
  const location = useLocation()
  const history = useHistory()
  const params = queryString.parse(location.search.slice(1, 100000))
  const { formatMessage } = useIntl()
  const {  appendBreadcrumbs } = useSubheader()
  const { addToast } = useToasts();


  useEffect(() => {
    appendBreadcrumbs({
      title: formatMessage({ defaultMessage: 'Cài đặt' }),
      pathname: `/setting`
    })
    appendBreadcrumbs({
      title: formatMessage({defaultMessage: 'Tài khoản quảng cáo'}),
      pathname: `/setting/ads`
    })
  }, [location.pathname])



  return (
    <>
      <Helmet
        titleTemplate={formatMessage({ defaultMessage: "Tài khoản quảng cáo" }) + " - UpBase"}
        defaultTitle={formatMessage({ defaultMessage: "Tài khoản quảng cáo" }) + " - UpBase"}
      >
        <meta name="description" content={formatMessage({ defaultMessage: "Tài khoản quảng cáo" }) + " - UpBase"} />
      </Helmet>
      
      <Card >
        <CardBody>
            <AdsTable />
        </CardBody>
      </Card>
    </>
  )
}

