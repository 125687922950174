import gql from 'graphql-tag';

export default gql`
mutation scAdsAuthorizationCancel($store_id: Int!) {
  scAdsAuthorizationCancel(store_id: $store_id) {
    message
    store_id
    success
  }
}

`;
