import { useMutation } from '@apollo/client'
import { Field, Formik } from 'formik'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useToasts } from 'react-toast-notifications'
import * as Yup from 'yup'
import { InputVertical } from '../../../../_metronic/_partials/controls'
import { TooltipWrapper } from '../../Finance/payment-reconciliation/common/TooltipWrapper'
import LoadingDialog from '../../Products/product-new/LoadingDialog'
import mutate_scSaleAuthorizationGrant from '../../../../graphql/mutate_scSaleAuthorizationGrant'

const DialogConnectChannelAuthTypeKey = ({ show, name, code, onHide }) => {
    const { formatMessage } = useIntl()
    const { addToast } = useToasts()
    const [initialValues, setInitialValues] = useState({});

    const validateSchema = useMemo(() => {
        const schema = {
            username: Yup.string()
                .required(formatMessage({ defaultMessage: 'Vui lòng nhập tài khoản' }))
                .test(
                    'chua-ky-tu-space-o-dau-cuoi',
                    formatMessage({ defaultMessage: 'Tài khoản không được chứa dấu cách ở đầu và cuối' }),
                    (value, context) => {
                        if (!!value) {
                            return value.length == value.trim().length;
                        }
                        return false;
                    },
                )
                .test(
                    'chua-ky-tu-2space',
                    formatMessage({ defaultMessage: 'Tài khoản không được chứa 2 dấu cách liên tiếp' }),
                    (value, context) => {
                        if (!!value) {
                            return !(/\s\s+/g.test(value))
                        }
                        return false;
                    },
                ),
            password: Yup.string()
                .required(formatMessage({ defaultMessage: 'Vui lòng nhập mật khẩu' }))
                .test(
                    'chua-ky-tu-space-o-dau-cuoi',
                    formatMessage({ defaultMessage: 'Mật khẩu không được chứa dấu cách ở đầu và cuối' }),
                    (value, context) => {
                        if (!!value) {
                            return value.length == value.trim().length;
                        }
                        return false;
                    },
                )
                .test(
                    'chua-ky-tu-2space',
                    formatMessage({ defaultMessage: 'Mật khẩu không được chứa 2 dấu cách liên tiếp' }),
                    (value, context) => {
                        if (!!value) {
                            return !(/\s\s+/g.test(value))
                        }
                        return false;
                    },
                ),
            shop_api: Yup.string()
                .required(formatMessage({ defaultMessage: 'Vui lòng nhập doamin' }))
                .test(
                    'domain-invalid',
                    formatMessage({ defaultMessage: 'Domain không hợp lệ' }),
                    (value, context) => {
                        if (!!value) {
                            return (/^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,}$/.test(value))
                        }
                        return false;
                    },
                )
        }
        return Yup.object().shape(schema)
    }, [show]);

    useEffect(() => {
        let username = ''
        let password = ''
        setInitialValues(prev => ({
            ...prev,
            username,
            password,
            user_code: ''
        }));

    }, [])

    const [mutatePrvConnectProvider, { loading }] = useMutation(mutate_scSaleAuthorizationGrant,
        {
            refetchQueries: ['sme_brands', 'query_stores_channel'],
            awaitRefetchQueries: true
        })

    return (
        <div>
            <LoadingDialog show={loading} />

            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validateSchema}
                onSubmit={async (values) => {
                    try {
                        const { data } = await mutatePrvConnectProvider({
                            variables: {
                                connector_channel_code: code,
                                params: [{ key: "username", value: values?.username }, { key: "password", value: values?.password }, { key: "shop_api", value: values?.shop_api }]
                            }
                        })
                        if (data?.scSaleAuthorizationGrant?.success == 1) {
                            addToast(data?.scSaleAuthorizationGrant?.message || formatMessage({ defaultMessage: 'Kết nối thành công' }), { appearance: 'success' })
                        } else if (data?.scSaleAuthorizationGrant?.success == 2) {
                            addToast(data?.scSaleAuthorizationGrant?.message || formatMessage({ defaultMessage: 'Kết nối thất bại!' }), { appearance: 'error' })
                        }
                        else {
                            addToast(data?.scSaleAuthorizationGrant?.message || formatMessage({ defaultMessage: 'Kết nối thất bại' }), { appearance: 'error' })
                        }
                    } catch (err) {
                        addToast(data?.scSaleAuthorizationGrant?.message || formatMessage({ defaultMessage: 'Kết nối thất bại.' }), { appearance: 'error' })
                    }

                    onHide(true)
                }}>
                {({ values, handleSubmit, setFieldValue }) => {
                    return (
                        <Modal size="md" show={show} aria-labelledby="example-modal-sizes-title-sm" centered onHide={onHide} backdrop={true}>
                            <Modal.Header closeButton={false}><Modal.Title style={{ textTransform: "capitalize" }}>{formatMessage({ defaultMessage: "Kết nối {name}" }, { name: name })}</Modal.Title></Modal.Header>
                            <Modal.Body style={{ minHeight: 272 }}>
                                <Fragment>
                                    <div className='row d-flex align-items-center mb-2'>
                                        <div className='col-4 text-right'>
                                            <span>Tài khoản</span>
                                            <TooltipWrapper note={formatMessage({ defaultMessage: 'Nhập tài khoản.' })}>
                                                <i className="fas fa-info-circle fs-14 ml-2 mr-2"></i>
                                            </TooltipWrapper>
                                            <span className='text-primary'>*</span>
                                        </div>
                                        <div className='col-8 hiddenCountText pl-0'>
                                            <Field name='username'
                                                component={InputVertical}
                                                placeholder={formatMessage({ defaultMessage: "Nhập tài khoản", })}
                                                label={""}
                                                value={values['username']}
                                                required={true}
                                                customFeedbackLabel={" "}
                                                cols={["col-0", "col-12"]}
                                                countChar
                                                rows={2}
                                                maxChar={"50"} />
                                        </div>

                                    </div>

                                    <div className='row d-flex align-items-center mb-2'>
                                        <div className='col-4 text-right'>
                                            <span>Mật khẩu</span>
                                            <TooltipWrapper note={formatMessage({ defaultMessage: 'Nhập mật khẩu.' })}>
                                                <i className="fas fa-info-circle fs-14 ml-2 mr-2"></i>
                                            </TooltipWrapper>
                                            <span className='text-primary'>*</span>
                                        </div>
                                        <div className='col-8 hiddenCountText pl-0'>
                                            <Field name='password'
                                                component={InputVertical}
                                                placeholder={formatMessage({ defaultMessage: "Nhập mật khẩu", })}
                                                label={""}
                                                value={values['password']}
                                                required={true}
                                                customFeedbackLabel={" "}
                                                cols={["col-0", "col-12"]}
                                                countChar
                                                rows={2}
                                                maxChar={"50"} />
                                        </div>
                                    </div>

                                    <div className='row d-flex align-items-center'>
                                        <div className='col-4 text-right'>
                                            <span>Domain</span>
                                            <TooltipWrapper note={formatMessage({ defaultMessage: 'Nhập domain.' })}>
                                                <i className="fas fa-info-circle fs-14 ml-2 mr-2"></i>
                                            </TooltipWrapper>
                                            <span className='text-primary'>*</span>
                                        </div>
                                        <div className='col-8 hiddenCountText pl-0'>
                                            <Field name='shop_api'
                                                component={InputVertical}
                                                placeholder={formatMessage({ defaultMessage: "Nhập domain", })}
                                                label={""}
                                                value={values['shop_api']}
                                                required={true}
                                                customFeedbackLabel={" "}
                                                cols={["col-0", "col-12"]}
                                                countChar
                                                rows={2}
                                                maxChar={"50"} />
                                        </div>
                                    </div>
                                </Fragment>
                            </Modal.Body>
                            <Modal.Footer className="form" style={{ borderTop: "1px solid #dbdbdb", justifyContent: "end", paddingTop: 10, paddingBottom: 10 }}>
                                <div className="form-group">
                                    <button disabled={loading} type="button" className="btn mr-3" style={{ width: 100, background: 'gray', color: 'white' }} onClick={onHide}>
                                        {formatMessage({ defaultMessage: "Hủy" })}
                                    </button>
                                    <button disabled={loading} type="button" className="btn btn-primary mr-3" style={{ width: 100 }} onClick={handleSubmit}>
                                        {formatMessage({ defaultMessage: "Cập nhật" })}
                                    </button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                    )
                }}
            </Formik>
        </div>
    )
}

export default DialogConnectChannelAuthTypeKey