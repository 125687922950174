import React, { memo, useEffect, useRef, useMemo, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from "react-intl";
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import { useLazyQuery, useQuery } from "@apollo/client";
import scAdsAuthorizationUrl from '../../../../../graphql/scAdsAuthorizationUrl'


const ModalAddAds = ({
    onHide,
    isShow
}) => {
    const {formatMessage} = useIntl()
    const _form = useRef(null)
    const [authorize, { data: dataAuthozie, loading }] = useLazyQuery(scAdsAuthorizationUrl)

    const _onHide = useMemo(() => {
        if (!!dataAuthozie || loading) {
          return null
        }
        return onHide
      }, [dataAuthozie, loading])
    
    const saveClick = useCallback(() => {
    authorize({
        variables: {
            connector_channel_code: 'shopee'
        }
    })
    }, []);

    useMemo(() => {
    if (!!dataAuthozie && !!dataAuthozie.scAdsAuthorizationUrl && !!dataAuthozie.scAdsAuthorizationUrl.authorization_url) {
        window.location.replace(dataAuthozie.scAdsAuthorizationUrl.authorization_url)
    }

    }, [dataAuthozie])
    return <Modal
        show={isShow}
        aria-labelledby="example-modal-sizes-title-sm"
        dialogClassName="modal-show-connect-product"
        centered
        onHide={() => {
            // setChannelsSelected()
            _onHide()
        }}
        backdrop={true}

    >
        <Modal.Body className="overlay overlay-block cursor-default">
            <p className='mb-4' style={{fontSize: 14, fontWeight: 'bold', textAlign: 'center'}}>{formatMessage({defaultMessage: 'Vui lòng chọn sàn bạn muốn kết nối'})}</p>
            <div className='d-flex justify-content-center mt-8'>
            <div
                className="radio-list"
            >
                <label
                    className="radio"
                >
                    <input
                        type="checkbox"
                        value={'shopee'}
                        checked={true}
                    />
                    <span></span>
                    <div style={{ width: 20, height: 20, marginRight: "8px" }}>
                        <img src={toAbsoluteUrl('/media/logo_shopee.png')} className={`mr-2`} style={{ width: 20 }} />
                    </div>
                    Shopee
                </label>
            </div>
            </div>
            <div className="d-flex justify-content-center mt-8">
                <button
                    type="button"
                    onClick={() => {
                        // setChannelsSelected()
                        _onHide()
                      }}
                    disabled={!_onHide}
                    className="btn btn-secondary btn-elevate mr-3"
                    style={{minWidth: 80}}
                >
                    {formatMessage({defaultMessage:"Huỷ"})}
                </button>
                <button
                    type="button"
                    onClick={() => {
                        saveClick()
                    }}
                    className="btn btn-primary btn-elevate ml-3"
                    style={{minWidth: 80}}
                    disabled={loading}
                >
                    {formatMessage({defaultMessage:"Thêm"})}
                </button>
            </div>
        </Modal.Body>
    </Modal>
};

export default memo(ModalAddAds);