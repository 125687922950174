import queryString from 'querystring';
import React, { useEffect, useMemo } from "react";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { Card, CardBody, } from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import ChannelsPage from "./ChannelsPage";
import AuthorizationWrapper from "../../../../components/AuthorizationWrapper";

export default function Channels() {
  const location = useLocation()
  const params = queryString.parse(location.search.slice(1, 100000))
  const { formatMessage } = useIntl()
  const { setToolbar, appendBreadcrumbs, setBreadcrumbs } = useSubheader()

  let tab = useMemo(() => {
    try {
      let _tab = Number(params.tab)
      if (!Number.isNaN(_tab)) {
        return Math.max(1, _tab)
      } else {
        return 1
      }
    } catch (error) {
      return 1
    }
  }, [params.tab])

  useEffect(() => {
    setToolbar({
      key: '/setting/channels',
      value: ""
    })
    let title = ""
    switch (tab) {
      case 1:
        setToolbar({
          key: '/setting/channels',
          value: ""
        })
        title = formatMessage({ defaultMessage: 'Quản lý gian hàng' })
        break;
      case 2:
        setToolbar({
          key: '/setting/channels',
          value: ""
        })
        title = formatMessage({ defaultMessage: 'Cài đặt đồng bộ tồn kho' })
        break;

      default:
        break;
    }
    setBreadcrumbs([])
    appendBreadcrumbs({
      title: formatMessage({ defaultMessage: 'Cài đặt' }),
      pathname: `/setting`
    })
    appendBreadcrumbs({
      title: title,
      pathname: `/setting/channels`
    })
  }, [location.pathname, tab])

  return (
    <>
      <Helmet
        titleTemplate={formatMessage({ defaultMessage: "Kết nối gian hàng" }) + "- UpBase"}
        defaultTitle={formatMessage({ defaultMessage: "Kết nối gian hàng" }) + "- UpBase"}
      >
        <meta name="description" content={formatMessage({ defaultMessage: "Kết nối gian hàng" }) + "- UpBase"} />
      </Helmet>
      <Card >
        <CardBody>
          <AuthorizationWrapper keys={['setting_channel_action']}>
            <div className="d-flex justify-content-end my-3">
              <Link to='/setting/channels/add' className="btn btn-primary"><FormattedMessage defaultMessage="THÊM GIAN HÀNG" /></Link>
            </div>
          </AuthorizationWrapper>
          <ChannelsPage />
        </CardBody>
      </Card>
    </>
  )
}

export const actionKeys = {
  "setting_channel_view": {
    router: '/setting/channels',
    actions: [
      "sc_store",
      "scGetTrackingLoadOrder",
      "sc_stores",
      "op_connector_channels",
      "scFindStoreProductSync",
      "scGetWarehouseMapping",
      "sme_warehouses",
      "scSaleAuthorizationUrl"
    ],
    name: 'Danh sách quản lý gian hàng',
    group_code: 'setting_channel_list',
    group_name: 'Quản lý gian hàng',
    cate_code: 'setting_service',
    cate_name: 'Cài đặt',
  },
  "setting_channel_detail": {
    router: '/setting/channel/:id',
    actions: [
      "sc_store",
      "scSaleAuthorizationUrl",
      "scProductSyncDown",
      "scUpdateStore",
      "scLoadInfoStore",
      "scSaleAuthorizationCancel"
    ],
    name: 'Các thao tác trong màn chi tiết gian hàng',
    group_code: 'setting_channel_list',
    group_name: 'Quản lý gian hàng',
    cate_code: 'setting_service',
    cate_name: 'Cài đặt',
  },
  "setting_channel_action": {
    router: '/setting/channels',
    actions: [
      "scCreateStoreChannelOther",
      "scCheckNameStoreExist",
      "scSaleAuthorizationUrl",
      "scSaleAuthorizationCancel",
      "scSyncCategory",
      "scProductLoad",
      "scDisconnectStoreChannelOther",
      "scOrderLoad",
      "scLoadReturnOrder",
      "sc_stores",
      "sc_store",
      "op_connector_channels",
      "sc_sale_channel_categories",
      "scSyncBrandByCategory",
      "scGetLastJobTracking",
      "sc_job_tracking",
      "scFindTrackingLoadRating",
      "scReloadRatingComment",
      "query_sc_stores_basic",
      "scSaleAuthorizationGrant"
    ],
    name: 'Các thao tác trong màn quản lý gian hàng',
    group_code: 'setting_channel_list',
    group_name: 'Quản lý gian hàng',
    cate_code: 'setting_service',
    cate_name: 'Cài đặt',
  }
};
