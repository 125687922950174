import React, { memo } from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import { useIntl } from "react-intl";

const ModalDownloadBrandProcess = ({ show, onHide, currentInfoStore, nameStore, progressBar }) => {
  const { formatMessage } = useIntl();
  const _onHide = () => {
    onHide();
  }

  return (
    <Modal
      onHide={_onHide}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
      centered
    >
      <Modal.Header className='px-3 py-1'>
        <h5 class="modal-title" style={{ fontWeight: 600 }}>{formatMessage({ defaultMessage: "Tải thương hiệu" })}</h5>
        <button type="button" data-dismiss="modal" aria-label="Close" style={{ background: "transparent" }} onClick={_onHide}>
          <span aria-hidden="true" style={{ fontSize: "32px", lineHeight: "32px" }}>&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default text-center">
        <div className="fs-14 d-flex mb-4">
          <label className='text-left' style={{ color: '#000000', width: "100px" }}>{formatMessage({ defaultMessage: 'Gian hàng' })}:</label>
          {currentInfoStore && nameStore(currentInfoStore)}
        </div>
        <div className="fs-14 d-flex">
          <ProgressBar style={{ height: '21px', fontSize: '14px', width: "100%" }}
            now={progressBar} label={`${progressBar}%`} />
        </div>
      </Modal.Body>
      <Modal.Footer className='p-2'>

        <button
          onClick={() => {
            _onHide()
          }}
          type='submit'
          className="btn btn-primary py-2 px-6"
          style={{ color: "#fff" }}
        >
          {formatMessage({ defaultMessage: "Đóng" })}
        </button>
      </Modal.Footer>
    </Modal>

  )
}

export default memo(ModalDownloadBrandProcess)