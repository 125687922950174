import gql from 'graphql-tag';

export default gql`
query scAdsAuthorizationUrl($connector_channel_code: String!) {
  scAdsAuthorizationUrl(connector_channel_code: $connector_channel_code) {
    authorization_url
  }
}

`;
