import React from "react";

const inputLabel = ({ label, touched, error, customFeedbackLabel, absolute }) => {
  if (touched && error) {
    return <div className="invalid-feedback " style={absolute ? {
      position: 'absolute', top: 36, textOverflow: 'ellipsis',
      whiteSpace: 'wrap', overflow: 'hidden', display: 'block',
      fontSize: 10
    } : { display: 'block' }} >{error}</div>;
  }

  return null;
};

export function FieldFeedbackLabel({
  label,
  touched,
  error,
  type,
  customFeedbackLabel,
  absolute = false
}) {
  return inputLabel({ label, touched, error, customFeedbackLabel, absolute });
}
