import gql from 'graphql-tag';

export default gql`
    query sc_ads {
        sc_ads {
            activeConnectorAuthorization {
                access_token_expired_at
                access_token
                refresh_token
                refresh_token_expired_at
                id
                auth_code
            }
            active_authorization_id
            authorization_expired_at
            connector_channel_code
            id
            last_connected_at
            last_disconnected_at
            name
            ref_shop_id
            sme_id
            status
            store_id
        }
    }`