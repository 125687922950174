import gql from 'graphql-tag';

export default gql`
mutation scAdsAuthorizationGrant($params: [AdsAuthorizationParams]!, $connector_channel_code: String!) {
  scAdsAuthorizationGrant(connector_channel_code: $connector_channel_code, params: $params) {
    message
    success
  }
}

`;
